import React, { useState } from 'react';
import { string } from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import css from './UserSendInvitationPage.module.css';
import { contactUsMessage, mailchimp, mailchimpSendInvitation } from '../../util/api';
import { contactUsMessageOldUser } from '../../util/api';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/ui.duck';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withViewport } from '../../util/uiHelpers';
import UserSendInvitationForm from './UserSendInvitationForm';
import { types as sdkTypes } from '../../util/sdkLoader';
import { requestCreateListingDraft } from './UserSendInvitation.duck';
import moment from 'moment';
import toast from 'react-hot-toast';

const UserSendInvitationPage = props => {
  const {
    intl,
    scrollingDisabled,
    currentUser,
    userShowError,
    userId,
    setIsOpen,
    onCreateListingDraft,
  } = props;

  const photoGrapherId = currentUser && currentUser.id?.uuid;
  const photoGrapherName = currentUser?.attributes?.profile?.displayName;
  const photoGrapherImageUrl =
    currentUser?.profileImage?.attributes?.variants?.['square-small2x'].url;
  const dateAndTime = moment().format('MMMM Do YYYY, h:mm:ss a');

  const [isSendMail, setIsSendMail] = useState(false);
  const [contactSubmitModal, setContactSubmitModal] = useState(false);
  const title = intl.formatMessage({ id: 'UserSendInvitationPage.title' });

  const handleSubmit = values => {
    const { firstName, lastName, email, message, description } = values;
    const params = { ...values, email: email?.toLowerCase(), dateAndTime, photoGrapherId };

    onCreateListingDraft(params)
      .then(async res => {
        const { listingData, userAlreadyExist } = res || {};
        const listingId = listingData?.data?.data?.id?.uuid;

        if (!userAlreadyExist) {
          (await listingId) &&
            contactUsMessage({
              firstName,
              lastName,
              email: email?.toLowerCase(),
              message,
              photoGrapherId,
              photoGrapherName,
              photoGrapherImageUrl,
              listingId: listingId,
            });
          await mailchimp({ ...values })
            .then(res => {})
            .catch(err => {
              console.log(err);
            });
          toast.success('Invitation sent successfully!');
          setIsOpen(false);
          return res;
        }

        (await listingId) &&
          contactUsMessageOldUser({
            firstName,
            lastName,
            email: email?.toLowerCase(),
            message,
            photoGrapherId,
            photoGrapherName,
            photoGrapherImageUrl,
            listingId: listingId,
          });
        return res;
      })
      .catch(err => {
        console.log('Something went wrong');
        console.error(err);
      });
  };

  return (
    <div className={css.contactWrapper}>
      <UserSendInvitationForm
        className={css.form}
        onSubmit={handleSubmit}
        isSendMail={isSendMail}
      />
    </div>
  );
};

UserSendInvitationPage.defaultProps = {
  rootClassName: null,
  className: null,
};

UserSendInvitationPage.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onCreateListingDraft: (values, config) => dispatch(requestCreateListingDraft(values, config)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

const UserSendInvitation = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withViewport,
  injectIntl
)(UserSendInvitationPage);

export default injectIntl(UserSendInvitation);
